import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAdminAuth } from '@/contexts/adminAuth'
import Image from 'next/image'
import { Input, Form, notification } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import '@/styles/antd.less'
import {
  LoginContainer,
  LoginContent,
  LoginContentInner,
  LoginLogo,
  StyledFormItem,
  StyledLink,
  LoginButton,
} from '@/styles/pages/retailers/login'
import { Head } from 'next/document'

interface FormProps {
  email: string
  password: string
}

export default function RetailerLogin() {
  const router = useRouter()
  const { isAuthenticated, login, setIsAuthenticated } = useAdminAuth()
  const [form] = Form.useForm()
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      router.replace('/')
    }
  }, [isAuthenticated, router])

  async function onSubmit(data: FormProps): Promise<void> {
    const response = await login(data.email, data.password)

    if (response.error) {
      let errorMessage = 'Não foi possível efetuar o login, por favor tente novamente'

      switch (response.error) {
        case 'Invalid user credentials':
          errorMessage = 'Dados de acesso inválido'
          break
        default:
          errorMessage = 'Não foi possível efetuar o login, por favor tente novamente'
          break
      }

      notification.error({
        message: 'Erro',
        description: errorMessage,
      })

      setIsButtonLoading(false)
      return
    } else {
      setIsButtonLoading(false)
      setIsAuthenticated(true)
    }
  }

  return (
    <>
      <LoginContainer>
        <LoginContent>
          <LoginContentInner>
            <LoginLogo>
              <img
                src="/assets/images/swig-logo-purple-full.svg"
                alt="SWIG"
                width="134"
                height="72"
                className="account-form-logo"
              />
            </LoginLogo>

            <Form layout="vertical" size="large" onFinish={onSubmit} method="POST">
              <StyledFormItem
                name="email"
                label="Endereço de email"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { type: 'email', message: 'O email inserido não é um email válido' },
                ]}
              >
                <Input type="email" placeholder="Endereço de email" />
              </StyledFormItem>

              <StyledFormItem
                name="password"
                label="Palavra-passe"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Password
                  name="password"
                  placeholder="Palavra-passe"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </StyledFormItem>

              <Link href="/forgot-password">
                <StyledLink> Esqueceu-se da palavra-passe? </StyledLink>
              </Link>

              <Form.Item shouldUpdate>
                {() => (
                  <LoginButton
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    loading={isButtonLoading}
                  >
                    {isButtonLoading ? 'A entrar ...' : 'Entrar'}
                  </LoginButton>
                )}
              </Form.Item>
            </Form>
          </LoginContentInner>
        </LoginContent>
      </LoginContainer>
    </>
  )
}
