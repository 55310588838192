import styled from 'styled-components'
import { Button, Form } from 'antd'

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
`

export const LoginContent = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoginContentInner = styled.div`
  width: 100%;
  max-width: 380px;
  box-shadow: 0px 3px 5px -1px rgba(67, 4, 59, 0.06);
  background: #ffffff;
  padding: 40px 40px 60px;
`

export const LoginLogo = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
  .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border-radius: 0;
    border: 1px solid #e2e2e2;

    &:focus {
      border-color: #87487c;
    }
  }
  .ant-input::-webkit-input-placeholder {
    font-family: Lato;
  }

  .ant-input:-moz-placeholder {
    font-family: Lato;
  }

  .ant-input::-moz-placeholder {
    font-family: Lato;
  }

  .ant-input:-ms-input-placeholder {
    font-family: Lato;
  }

  .ant-input::input-placeholder {
    font-family: Lato;
  }

  .ant-input::placeholder {
    font-family: Lato;
  }

  & .ant-form-item-label label {
    font-family: Lato;
    line-height: 24px;
    height: auto;

    &.ant-form-item-required:before {
      display: none;
    }
  }

  & .ant-input-lg {
    padding: 14px 11px;
    font-size: 14px;
  }

  & .ant-form-item-explain {
    font-size: 13px;
    margin-top: 5px;
  }
`

export const StyledLink = styled.a`
  margin-bottom: 24px;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: Lato;
  font-weight: 400;
  color: #7b2e6f;

  &:hover {
    color: #b488ad;
  }
`

export const LoginButton = styled(Button)`
  width: 100%;
  height: 40px;
  background: #7b2e6f;
  border-color: #7b2e6f;
  box-shadow: none;

  &:hover,
  &:focus {
    background: #b488ad;
    border-color: #b488ad;
  }
`
